<template>
  <div>
    <h2>Согласование данных</h2>

    <search-block title="данных">
      <el-row :gutter="10">
        <el-col :span="3">
          <el-select
            clearable
            class="searchPole"
            v-model="search.waste"
            filterable
            remote
            value-key="ss01DictionaryId"
            reserve-keyword
            placeholder="Наименование"
          >
            <el-option
              v-for="item in wasteNames"
              :key="item.ss01WasteDictionaryDTO.ss01DictionaryId"
              :label="item.ss01WasteDictionaryDTO.wasteName"
              :value="item.ss01WasteDictionaryDTO"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="3">
          <el-select
            clearable
            class="searchPole"
            v-model="search.waste"
            filterable
            remote
            value-key="ss01DictionaryId"
            reserve-keyword
            placeholder="Код отхода"
          >
            <el-option
              v-for="item in wasteNames"
              :key="item.ss01WasteDictionaryDTO.ss01DictionaryId"
              :label="item.ss01WasteDictionaryDTO.wasteCode"
              :value="item.ss01WasteDictionaryDTO"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="3">
          <el-date-picker
            style="width: 100%"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="Дата начала"
            v-model="search.dateFrom"
          >
          </el-date-picker>
        </el-col>

        <el-col :span="3">
          <el-date-picker
            style="width: 100%"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="Дата окончания"
            v-model="search.dateTo"
          >
          </el-date-picker>
        </el-col>

        <el-col :span="3">
          <el-select
            @change="changeBranch"
            clearable
            filterable
            class="searchPole"
            v-model="search.branch"
            value-key="id"
            placeholder="Филиал"
          >
            <el-option
              v-for="item in branchesList"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
            @change="changeSubDivision"
            :disabled="subDivisionsList.length === 0"
            clearable
            filterable
            class="searchPole"
            v-model="search.subDivision"
            value-key="id"
            placeholder="Подразделение"
          >
            <el-option
              v-for="item in subDivisionsList"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
            :disabled="districtsList.length === 0"
            clearable
            filterable
            class="searchPole"
            v-model="search.district"
            value-key="id"
            placeholder="Участок"
          >
            <el-option
              v-for="item in districtsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
            clearable
            filterable
            class="searchPole"
            v-model="search.actionInfo"
            value-key="id"
            placeholder="Тип движения"
          >
            <el-option
              v-for="item in actionId"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <br />
      <el-row :gutter="10">
        <el-col :span="2">
          <el-button @click="searchWasteHistory(0,20)" type="primary">
            Поиск
          </el-button>
        </el-col>
        <el-col :span="2">
          <el-button @click="clearSearch" type="success"> Сброс </el-button>
        </el-col>
      </el-row>
    </search-block>

    <el-table stripe :data="dataAgreement.content">
      <el-table-column label="Дата операции" prop="date">
        <template slot-scope="item">
          {{/* item.row.wasteHistoryId + "\\" +*/ item.row.date }}
        </template>
      </el-table-column>
      <el-table-column
        label="Тех. процесс"
        prop=""
      >
        <template slot-scope="item">
          {{ item.row.techProcessInfo.name.slice(0, item.row.techProcessInfo.name.length-(item.row.organizationName.length+1))}} 
          <b>{{ item.row.organizationName}}</b>{{ ")" }}
        </template>
    
      </el-table-column>
      <el-table-column label="Отход" prop="wasteInfo.name"></el-table-column>
      <el-table-column
        label="Кто внес"
        prop=""
      >
      <template slot-scope="item">
          {{item.row.fullName == null ? item.row.createdByInfo.name : item.row.fullName}} 
          <div v-if="item.row.roleIdUpdate ==1 && item.row.createdByInfo.name2 != '1' && role != 1">
            (отредактировано главным экологом)
          </div>
          <div v-if="item.row.roleIdUpdate ==3 && role == 2">
            (отредактировано старшим экологом)
          </div>
      </template >
      </el-table-column>
      <el-table-column
        label="Движение"
        prop=""
      >
        <template slot-scope="item">
          {{item.row.actionInfo.name}}
          <br>
          <div v-if="item.row.passportNumber != 0"><i>{{ "Сопроводительный паспорт №:"+ item.row.passportNumber }}</i></div>
        </template>
      </el-table-column>
      <el-table-column label="Количество" prop="inputAmount">
        <template slot-scope="item">
          {{ item.row.inputAmount + " " + item.row.pss93Info.name }}
        </template>
      </el-table-column>
      <el-table-column
        label="Контрагент/ Стр.подразделение"
        prop="storedThen"
      >
        <template slot-scope="item" v-if="item.row.actionInfo.id == 10 && item.row.receivedFromPerson==true">
          {{"Физическое лицо" + (item.row.fio!=null ? (": "+item.row.fio) : "")  }}
        </template>
        <template slot-scope="item">
          {{(item.row.actionInfo.id == 10 || (item.row.actionInfo.id == 2 && item.row.received_transferred_from_organization_id==null) ) && item.row.receivedFromPerson == true ? ("Физическое лицо" + (item.row.fio!=null ? (": "+item.row.fio) : "") ): null}}
          {{ item.row.contractor_name }}
          {{item.row.received_transferred_from_organization_id}}
        </template>
      </el-table-column>
      <el-table-column
        label="Примечание"
        prop=""
      >
      <template slot-scope="item">
        <div v-if="item.row.pss17ReceiptReasonsCode != 0">{{ pss17.find(x => x.id === item.row.pss17ReceiptReasonsCode).reason }}</div>
        <div v-if="item.row.pss18TransferReasonsCode != 0 && item.row.actionInfo.id == 10">{{ pss18.find(x => x.id === item.row.pss18TransferReasonsCode).reason }}</div>
        <div v-if="item.row.pss18TransferReasonsCode != 0 && item.row.actionInfo.id != 10">{{ pss182.find(x => x.id === item.row.pss18TransferReasonsCode).reason }}</div>
        <div v-if="item.row.pss19DirectionsOfUseCode != 0">{{ pss19.find(x => x.id === item.row.pss19DirectionsOfUseCode).reason }}</div>
        <div v-if="item.row.pss20NeutralizeMethodsCode != 0">{{ pss20.find(x => x.id === item.row.pss20NeutralizeMethodsCode).reason }}</div>
        <div v-if="item.row.pss21BurialSidesCode != 0">{{ pss21.find(x => x.id === item.row.pss21BurialSidesCode).reason }}</div>
        <div v-if="item.row.pss22StoreSidesCode != 0">{{ pss22.find(x => x.id === item.row.pss22StoreSidesCode).reason }}</div>
      </template>
      </el-table-column>
      <!--
      <el-table-column
        label="Хранится фактически на данный момент"
        prop="storedNowFact"
      >
        <template slot-scope="item">
          {{ item.row.storedNowFact + " " + item.row.pss93Info.name }}
        </template>
      </el-table-column>
      <el-table-column
        label="Причина редактирования"
        prop="changeReason"
      ></el-table-column>-->
      <el-table-column label="" prop="">
        <template slot-scope="item">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button v-bind:disabled="tphwInclude.find(el=>el==item.row.techProcessInfo.id)!=undefined || (new Date(item.row.date.split('.')[2],item.row.date.split('.')[1]-1,item.row.date.split('.')[0])<=new Date(2025,0,31) && (userId==469 || userId==470 || userId==471)) || (new Date(item.row.date.split('.')[2],item.row.date.split('.')[1]-1,item.row.date.split('.')[0])<=new Date(2025,1,28) && (userId==482 || userId==501)) || (((role == 2 && (userId!=item.row.createdByInfo.id || item.row.roleIdUpdate !=2 ))|| (role == 3 && (item.row.roleIdUpdate ==1 || item.row.createdByInfo.name2 == '1'))) || (new Date(item.row.date.split('.')[2],item.row.date.split('.')[1]-1,item.row.date.split('.')[0])<new Date(new Date().getFullYear(),0,1) && (userId!=409   && userId!=265 && userId!=266 && userId!=267
&& userId!=268 && userId!=269 && userId!=270 && userId!=271
&& userId!=272 && userId!=273 && userId!=274 && userId!=275
&& userId!=276 && userId!=277 && userId!=278 && userId!=279
&& userId!=280 && userId!=281 && userId!=282 && userId!=283
&& userId!=284 && userId!=285 && userId!=286 && userId!=287
&& userId!=288 && userId!=289 && userId!=290  && userId!=233 && userId!=234 && userId!=25 && userId!=292 && userId!=245 && userId!=14 && userId!=465 && userId!=505 && userId!=506 && userId!=507 && userId!=428 && userId!=124 && userId!=464 && userId!=557 && userId!=559 ))) "
                size="mini"
                style="width: 100%"
                @click="showUpdateModal(item.row)"
                type="warning"
                >Редактировать
              </el-button>
              <el-button v-bind:disabled="tphwInclude.find(el=>el==item.row.techProcessInfo.id)!=undefined || (new Date(item.row.date.split('.')[2],item.row.date.split('.')[1]-1,item.row.date.split('.')[0])<=new Date(2025,0,31) && (userId==469 || userId==470 || userId==471)) || (new Date(item.row.date.split('.')[2],item.row.date.split('.')[1]-1,item.row.date.split('.')[0])<=new Date(2025,1,28) && (userId==482 || userId==501)) || (((role == 2 && (userId!=item.row.createdByInfo.id || item.row.roleIdUpdate !=2 ))|| (role == 3 && (item.row.roleIdUpdate ==1 || item.row.createdByInfo.name2 == '1'))) || (new Date(item.row.date.split('.')[2],item.row.date.split('.')[1]-1,item.row.date.split('.')[0])<new Date(new Date().getFullYear(),0,1) && (userId!=409   && userId!=265 && userId!=266 && userId!=267
&& userId!=268 && userId!=269 && userId!=270 && userId!=271
&& userId!=272 && userId!=273 && userId!=274 && userId!=275
&& userId!=276 && userId!=277 && userId!=278 && userId!=279
&& userId!=280 && userId!=281 && userId!=282 && userId!=283
&& userId!=284 && userId!=285 && userId!=286 && userId!=287
&& userId!=288 && userId!=289 && userId!=290  && userId!=233 && userId!=234 && userId!=25 && userId!=292 && userId!=245 && userId!=14 && userId!=465 && userId!=505 && userId!=506 && userId!=507 && userId!=428 && userId!=124 && userId!=464 && userId!=557 && userId!=559 ))) "
                @click="deleteAgreement(item.row)"
                size="mini"
                style="width: 100%; margin-left: 0; margin-top: 5px"
                type="danger"
                >Удалить
              </el-button>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="dataAgreement.size"
      :current-page.sync="dataAgreement.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="dataAgreement.totalElements"
    >
    </el-pagination>

    <custom-modal
      title="Изменить"
      :visible="showModal === 'updateDataAgreement'"
    >
      <formed-modal-update v-on:getListAllHistorychildren="handleCurrentChangeZero"
        :action="selectedAction"
      ></formed-modal-update>
    </custom-modal>
  </div>
</template>

<script>
import SearchBlock from "@/components/searchBlock";
import { mapGetters } from "vuex";
import CustomModal from "@/components/customModal";
import FormedModalUpdate from "@/components/ecologist/dataEntry/modals/update/formedModalUpdate";
import notification from "@/mixins/notification";
import Cookies from "js-cookie";
export default {
  name: "dataAgreementMain",
  mixins: [notification],
  components: { FormedModalUpdate, CustomModal, SearchBlock },
  data() {
    return {
      tphwInclude:[],
      actionId:[{id:0, name: "Все"},
            { id: 1, name: "Образовалось" },
            { id: 2, name: "Поступило" },
            { id: 3, name: "Передано" },
            { id: 4, name: "Обезврежено" },
            { id: 5, name: "Использовано" },
            { id: 6, name: "Захоронено" },
            { id: 8, name: "Направлено на хранение" },
            { id: 10, name: "Передано контрагенту" },],
      pss17:[{id:1,reason:"Для использования"},
      {id:2,reason:"Для обезвреживания"},
      {id:3,reason:"Для захоронения"},
      {id:4,reason:"Для хранения"},
      {id:5,reason:"Прочее"},],
      pss18:[{id:1,reason:"На использование"},
      {id:2,reason:"На обезвреживание"},
      {id:3,reason:"На захоронение по договору отчуждения"},
      {id:4,reason:"На хранение"},
      {id:5,reason:"Прочее"},
      {id:6,reason:"На захоронение без договора отчуждения"},
      {id:7,reason:"С целью заготовки"},
      {id:8,reason:"С целью сортировки"},],
      pss182:[{id:1,reason:"С целью использования"},
      {id:2,reason:"С целью обезвреживания"},
      {id:3,reason:"С целью захоронения"},
      {id:4,reason:"С целью хранения"},
      {id:5,reason:"Прочее"},
      {id:6,reason:"С целью захоронение без договора отчуждения"},],
      pss19:[{id:1,reason:"Для получения энергии"},
      {id:2,reason:"Для получения продукции"},
      {id:3,reason:"Для выполнения работ, оказания услуг"},
      {id:4,reason:"Для получения RDF-топлива"},
      {id:5,reason:"В качестве изолирующего материала на объектах захоронения твердых коммунальных отходов"},
      {id:6,reason:"Для рекультивации нарушенных земель"},],
      pss20:[{id:110,reason:"Термический"},
      {id:111,reason:"огневой"},
      {id:112,reason:"жидкофазного окисления"},
      {id:113,reason:"газификации"},
      {id:114,reason:"пиролиз"},
      {id:115,reason:"плазменный"},
      {id:116,reason:"сжигание (температура 1200 °C и выше)"},
      {id:117,reason:"двухступенчатое пиролитическое сжигание (температура 800 - 850 °C)"},
      {id:118,reason:"сжигание в однокамерных печах (температура 300 - 400 °C)"},
      {id:119,reason:"влажная термическая обработка (автоклав)"},
      {id:120,reason:"Физико-химическая обработка"},
      {id:121,reason:"коагуляция"},
      {id:122,reason:"адсорбция"},
      {id:123,reason:"экстракция"},
      {id:124,reason:"флотация"},
      {id:125,reason:"ионообменный метод (обеспечивает при пропускании жидких отходов через ионообменные материалы удаление катионов и анионов загрязнителей)"},
      {id:130,reason:"Электрохимический  способ"},
      {id:131,reason:"электролиз"},
      {id:132,reason:"электрокоагуляция"},
      {id:133,reason:"электрофлотация"},
      {id:140,reason:"Иммобилизация"},
      {id:150,reason:"Химический способ"},
      {id:151,reason:"нейтрализация"},
      {id:152,reason:"окислительный"},
      {id:153,reason:"химическая дезинфекция"},
      {id:160,reason:"Восстановительный способ"},
      {id:170,reason:"Мембранный способ"},
      {id:180,reason:"Биологическая обработка"},
      {id:190,reason:"Другое"},],
      pss21:[{id:211,reason:"Полигон промышленных отходов"},
      {id:212,reason:"Полигон токсичных промышленных отходов"},
      {id:221,reason:"Полигон твердых коммунальных отходов "},
      {id:222,reason:"Мини-полигон твердых коммунальных отходов "},
      {id:290,reason:"Другое"},],
      pss22:[{id:10,reason:"Шламохранилище (шламонакопитель)"},
      {id:20,reason:"Полигон токсичных промышленных отходов"},
      {id:30,reason:"Отвал"},
      {id:40,reason:"Объект хранения ила активного очистных сооружений"},
      {id:50,reason:"Подземный резервуар"},
      {id:60,reason:"Места временного хранения"},
      {id:70,reason:"Другое"},],
      userId:Cookies.get('userId') ,
      role: Cookies.get('role'),
      serch: 0,
      branchesList: [],
      subDivisionsList: [],
      districtsList: [],
      search: {
        actionInfo:{
          id: 0, name: "Все"
        },
        dateFrom: null,
        dateTo: null,
        waste: null,
        wasteCode: null,
        branch: null,
        subDivision: null,
        district: null,
      },
      selectedAction: {
        actionInfo: {
          id: null,
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      dataAgreement: "GETLISTALLHISTORY",
      showModal: "GETSHOWMODAL",
      wasteNames: "GETLISTWASTEPRODUCTION",
    }),
  },

  methods: {
    clearSearch() {
      this.serch=0;
      this.search = {
        actionInfo:0,
        dateFrom: null,
        dateTo: null,
        waste: null,
        branch: null,
        subDivision: null,
        district: null,
      };
      this.searchWasteHistory(0,20);
    },

    changeBranch() {
      this.getUnitList(2, this.search.branch.id).then((response) => {
        this.subDivisionsList = response.data;
      });
      this.getUnitList(3, this.search.branch.id).then((response) => {
        this.districtsList = response.data;
      });
    },

    changeSubDivision() {
      this.getUnitList(3, this.search.subDivision.id).then((response) => {
        this.districtsList = response.data;
      });
    },

    async getUnitList(unitType, unitId) {
      if (unitId !== undefined) {
        return await this.$store.dispatch("organizationUnitsForSelect", {
          unitType: unitType,
          unitId: unitId,
        });
      }
    },

    searchWasteHistory(upage, usize) {
      this.serch=1;
      let orgId = null;
      console.log(this.search);
      if (this.search.branch !== null && this.search.branch != "") {
        orgId = this.search.branch.id;
      }
      if (this.search.subDivision !== null && this.search.subDivision != "") {
        orgId = this.search.subDivision.id;
      }
      if (this.search.district !== null && this.search.district != "") {
        orgId = this.search.district;
      }
      let data = {
        body: {
          searchDTO: {
            dateFrom: this.search.dateFrom,
            dateTo: this.search.dateTo,
            organizationId: orgId,
            actionInfo: this.search.actionInfo
          },
          wasteInfo: {
            id:
              this.search.waste !== null
                ? this.search.waste.ss01DictionaryId
                : null,
            name:
              this.search.waste !== null ? this.search.waste.wasteName : null,
            parent: this.search.waste !== null ? this.search.waste.dangerDegreeClassifierDTO.id : null,
          },
        },
        page: upage,
        size: usize,
      };
      this.$store.dispatch("searchHistory", data);
    },

    searchWasteName(query) {
      this.getWasteNames(query);
    },

    async getWasteNames() {
      await this.$store.dispatch("getOrganizationWastesListByOrganizationId");
    },
    showUpdateModal(item) {
      this.selectedAction = item;
      this.$store.dispatch("setShowModal", "updateDataAgreement");
    },

    deleteAgreement(item) {
      this.confirm(
        "Удалить данные?",
        "Вы уверены что хотите удалить данные",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store
          .dispatch("deleteWasteHistory", item)
          .then(() => {
            this.notification("Успешно", "Данные удалены", "success");
            this.handleCurrentChangeZero();
          });
      });
    },

    handleSizeChange(val) {
      if (this.serch==1)
      this.searchWasteHistory(0,val);
      else
      this.getListAllHistory({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      if (this.serch==1)
      this.searchWasteHistory(val - 1,this.dataAgreement.size);
      else
      this.getListAllHistory({ page: val - 1, size: this.dataAgreement.size });
    },

    handleCurrentChangeZero() {
      if (this.serch==1)
      this.searchWasteHistory(this.dataAgreement.number,this.dataAgreement.size);
      else
      this.getListAllHistory({ page: this.dataAgreement.number, size: this.dataAgreement.size });
    },

    getListAllHistory(data) {
      this.$store.dispatch("getListAllHistory", data);
    },
  },

  mounted() {
    this.getListAllHistory({ page: 0, size: 20 });
    this.getWasteNames("");

    this.getUnitList(1, 0).then((response) => {
      this.branchesList = response.data;
    });
    this.getUnitList(2, 0).then((response) => {
      this.subDivisionsList = response.data;
    });
    this.getUnitList(3, 0).then((response) => {
      this.districtsList = response.data;
    });
    console.log(this);
    console.log(Cookies.get('userId'));
  },
};
</script>

<style scoped>
.table {
  word-wrap: normal;
}
</style>
