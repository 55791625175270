<template>
  <div>
      <data-agreement-main></data-agreement-main>
  </div>
</template>

<script>
import DataAgreementMain from "@/components/ecologist/dataAgreement/dataAgreementMain";
export default {
  name: "dataAgreementView",
  components: { DataAgreementMain },
};
</script>

<style scoped></style>
